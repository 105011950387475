import axios from "axios";
import store from "../store";
import router from "../router";
import { domain } from "../Constants";

const http = new axios.create({
  baseURL: domain,
});

const httpMethod = {
  get(url, options = {}) {
    if (!store.state.auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
      .get(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${store.state.auth.accessToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log("error", err);
        if (err.response.status == 401) {
          window.localStorage.removeItem("vuex");
          location.reload();
          router.push("/login");
        }
        return Promise.reject(err);
      });
  },
  post(url, data = null, options = {}) {
    if (!store.state.auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
      .post(url, data, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${store.state.auth.accessToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log("error", err);
        if (err.response.status == 401) {
          window.localStorage.removeItem("vuex");
          location.reload();
          router.push("/login");
        }
        return Promise.reject(err);
      });
  },
  put(url, data = null, options = {}) {
    if (!store.state.auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
      .put(url, data, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${store.state.auth.accessToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log("error", err);
        if (err.response.status == 401) {
          window.localStorage.removeItem("vuex");
          location.reload();
          router.push("/login");
        }
        return Promise.reject(err);
      });
  },
  delete(url, options = {}) {
    if (!store.state.auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
      .delete(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${store.state.auth.accessToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log("error", err);
        if (err.response.status == 401) {
          window.localStorage.removeItem("vuex");
          location.reload();
          router.push("/login");
        }
        return Promise.reject(err);
      });
  },
};

export default httpMethod;
