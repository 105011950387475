<template>
  <v-container class="main-footer fixed-bar-footer pa-0">
    <v-row class="pa-0 ma-0">
      <v-col class="text-center pb-2" cols="4" @click="goto('/main')">
        <img src="@/assets/image/icon-footer-home.png" alt="" />
      </v-col>
      <v-divider
        vertical
        style="background: #e5e5e5; height: 45px; position: relative; top: 10px"
      ></v-divider>
      <v-col class="text-center pb-2" cols="4">
        <img src="@/assets/image/icon-footer-list.png" alt="" />
      </v-col>
      <v-divider
        vertical
        style="background: #e5e5e5; height: 45px; position: relative; top: 10px"
      ></v-divider>
      <v-col class="text-center pb-2" cols="4" @click="goto('problem')">
        <img src="@/assets/image/icon-footer-warning.png" alt="" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goto(param) {
      this.$router.push(param);
    },
  },
};
</script>

<style>
.v-footer {
  background: #f5f5f5;
  padding: 0;
  margin: 0;
}
</style>
