import Service from "../service";
import AuthService from "../AuthService";

const Auth = {
  login(data) {
    return Service.post("/login", data);
  },
  logout(data) {
    return AuthService.post("/logout", data);
  },
};

export default Auth;
