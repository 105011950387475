import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/main/login"),
  },
  {
    path: "/main",
    name: "main-page",
    component: () => import("../views/main/main"),
  },
  {
    path: "/report",
    name: "report-page",
    component: () => import("../views/main/report2"),
  },
  {
    path: "/report2",
    name: "report-page2",
    component: () => import("../views/main/report2"),
  },
  {
    path: "/problem",
    name: "problem-page",
    component: () => import("../views/main/problem"),
  },
  {
    path: "/import/:id",
    name: "import",
    props: true,
    component: () => import("../views/import/main"),
  },
  {
    path: "/export/:id",
    name: "export",
    props: true,
    component: () => import("../views/export/main"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem("vuex"));
  if ((token && token.auth.accessToken) || to.name === "login") {
    next();
  } else {
    next({ name: "login" });
  }
});

export default router;
