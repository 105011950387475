import AuthService from "@/service/AuthService";

const Main = {
    getForm(data) {
        return AuthService.get(`/job-detail?driver_job_id=${data.driver_job_id}&mode=${data.mode}`);
    },
    step1(data) {
        return AuthService.post(`/step1`,data);
    },
    step2(data) {
        return AuthService.post(`/step2`,data);
    },
    step3(data) {
        return AuthService.post(`/step3`,data);
    },
    step4(data) {
        return AuthService.post(`/step4`,data);
    },
    statusStep1(data) {
        return AuthService.post(`/status-step1`,data);
    },
    statusStep2(data) {
        return AuthService.post(`/status-step2`,data);
    },
};

export default Main;
