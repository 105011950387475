import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import job from "@/store/Modules/ImportModule";
import auth from "@/store/Modules/AuthModule";
import common from "@/store/Modules/CommonModule";

Vue.use(Vuex)

const modules = {
  job,
  auth,
  common
}

export default new Vuex.Store({
  modules,
  plugins: [createPersistedState()]
})
