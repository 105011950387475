<template>
  <v-container class="main-header fixed-bar pb-0">
    <v-row class="pa-0 ma-0">
      <template v-if="back">
        <v-col class="pl-0 pt-4" cols="1" @click="$router.go(-1)">
          <img src="@/assets/image/back.png" />
        </v-col>
        <v-col cols="10">
          <img src="@/assets/image/logo-tnl-mini.png" />
          <img src="@/assets/image/text-logo-tnl.png" />
        </v-col>
        <v-col class="px-0" cols="1" align-self="end">
          <img src="@/assets/image/menu.png" />
        </v-col>
      </template>
      <template v-else>
        <v-col cols="10" offset="1">
          <img src="@/assets/image/logo-tnl-mini.png" />
          <img src="@/assets/image/text-logo-tnl.png" />
        </v-col>
        <v-col class="px-0 ml-n1" cols="1" align-self="end">
          <v-btn dark icon @click="logout()">
            <v-icon dark>mdi-logout</v-icon>
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Header",
  props: {
    back: Boolean,
  },
  methods: {
    logout() {
      this.$swal({
        title: "ยืนยันการออกจากระบบ?",
        text: "คุณต้องการออกจากระบบหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.globalLoading();
          this.$store.dispatch("auth/logout").then(() => {
            this.globalHideLoading();
            this.$router.push("/");
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
