import Service from "../../service/index";

const Auth = {
    namespaced: true,
    state: {
        user: null,
        accessToken: null,
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.accessToken = token;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
    },
    actions: {
        login({ commit }, data) {
            return Service.auth.login(data).then(res => {
                commit("SET_TOKEN", res.access_token);
                commit("SET_USER", res.user);
                return res;
            }).catch(()=>{
                return false;
            });
        },
        logout({ commit }) {
            return Service.auth.logout().then(()=>{
                commit("SET_USER", null);
                commit("SET_TOKEN", null);
                localStorage.removeItem('vuex');
            })
        }
    }
};

export default Auth;
