import Service from "../../service/index";

const Common = {
  namespaced: true,
  state: {
    driver: [],
    driver_jobs: [],
    job_list: [],
  },
  mutations: {
    SET_WORK: (state, res) => {
      if (res.status === "success") {
        state.driver = res.data.driver;
        state.driver_jobs = res.data.driver_jobs;
      }
    },
    SET_JOBS: (state, res) => {
      state.job_list = res.data;
    },
  },
  actions: {
    home({ commit }) {
      return Service.common.home().then((res) => {
        commit("SET_WORK", res);
      });
    },
    list({ commit }, data) {
      return Service.common.list(data).then((res) => {
        commit("SET_JOBS", res);
      });
    },
    issue({ commit }, data) {
      return Service.common.issue(data);
    },
  },
};

export default Common;
