import Service from "../service";
import AuthService from "@/service/AuthService";

const Common = {
    home() {
        return AuthService.get("/index");
    },
    list(data) {
        return AuthService.get(`/job-list?sort=${data.sort}&filter=${data.filter}`);
    },
    issue(data){
        return AuthService.post("/save-report-issue",data)
    }
};

export default Common;
