import Service from "../../service/index";

const Job = {
  namespaced: true,
  state: {
    step: 1,
    form: null,
    statusStep: 1,
    status1: {
      date: "",
      position: "",
    },
    status2: {
      date: "",
      position: "",
    },
    status3: {
      deliver_container_image: "",
      goods_delivery_image: "",
      recipient_signature: "",
    },
  },
  mutations: {
    UPDATE_STEP: (state, data) => {
      state.step = data;
    },
    UPDATE_FORM: (state, res) => {
      state.form = res.data;

      // statusStep สำหรับ step 3
      state.statusStep = 1;
      if (res.data.factory_success_date) {
        state.statusStep = 2;
      }
      if (res.data.start_product_success_date) {
        state.statusStep = 3;
      }
      if (res.data.is_finish_success) {
        state.statusStep = 4;
      }
    },
    UPDATE_STATUS_STEP: (state, res) => {
      if (res.step == 2) {
        state.statusStep = res.step;
        state.status1.date = res.datetime;
        state.status1.position = res.latitude + " " + res.longitude;
        // state.status1.position = '13°08\'59.8"N 100°54\'08.';
      } else if (res.step == 3) {
        state.statusStep = res.step;
        state.status2.date = res.datetime;
        state.status2.position = res.latitude + " " + res.longitude;
        // state.status2.position = '13°08\'59.8"N 100°54\'08.';
      }
    },
    CLEAR_DATA: (state) => {
      state.step = 1;
      state.form = [];
      state.statusStep = 1;
      state.status1 = {
        date: "",
        position: "",
      };
      state.status2 = {
        date: "",
        position: "",
      };
      state.status3 = {
        deliver_container_image: "",
        goods_delivery_image: "",
        recipient_signature: "",
      };
    },
    SET_DATE_ARRIVE: (state, res) => {
      state.form.datetime_arrive = res.datetime_arrive
        ? res.datetime_arrive
        : "";
    },
  },
  actions: {
    updateStep({ commit }, data) {
      let form = {
        driver_job_id: data.driver_job_id,
        status: data.action,
      };
      if (data.step == 2)
        return Service.main.step1(form).then((res) => {
          commit("UPDATE_STEP", data.step);
          return res;
        });
      else if (data.step == 3) {
        return Service.main.step2(data).then((res) => {
          if (data.mode == "Empty" || data.mode == "Laden") data.step = 4;
          commit("UPDATE_STEP", data.step);
          commit("SET_DATE_ARRIVE", res);
          // commit("SET_E6", res);
          return res;
        });
      } else if (data.step == 4)
        return Service.main.step3(data).then((res) => {
          commit("UPDATE_STEP", data.step);
          commit("SET_DATE_ARRIVE", res);
          return res;
        });
      else if (data.step == 5)
        return Service.main.step4(data).then((res) => {
          return res;
        });
      else commit("UPDATE_STEP", 1);
    },
    getForm({ commit }, data) {
      return Service.main.getForm(data).then((res) => {
        if (res.data.step) {
          commit("UPDATE_STEP", res.data.step);
        } else {
          commit("UPDATE_STEP", 1);
        }
        commit("UPDATE_FORM", res);
        commit("SET_DATE_ARRIVE", res);
        return res;
      });
    },
    statusStep({ commit }, data) {
      if (data.step == 2)
        return Service.main.statusStep1(data).then((res) => {
          commit("UPDATE_STATUS_STEP", data);
          return res;
        });
      else if (data.step == 3)
        return Service.main.statusStep2(data).then((res) => {
          commit("UPDATE_STATUS_STEP", data);
          return res;
        });
      else commit("UPDATE_STATUS_STEP", 1);
    },
    clearStep({ commit }) {
      commit("CLEAR_DATA");
    },
  },
};
export default Job;
