<template>
  <v-app class="bg-main">
    <router-view></router-view>
  </v-app>
</template>
<style></style>
<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
export default {
  name: "App",
  data: () => ({}),
  components: { Header, Footer },
};
</script>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
}
</style>
