import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import GmapVue from "gmap-vue";
import VueSignature from "vue-signature-pad";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";
Vue.use(VueSignature);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
import "./assets/css/main.css";
import Swal from "sweetalert2";
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyAaeh0addM3Vj7u31bqzr9MZsdGtW_pic0",
    libraries: ["places"],
    region: "TH",
    language: "th",
  },
});
Vue.mixin({
  methods: {
    globalLoading: function () {
      this.$swal({
        title: "กำลังโหลด",
        allowEscapeKey: false,
        allowOutsideClick: false,
        heightAuto: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    globalHideLoading: function () {
      this.$swal.close();
    },
  },
});
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(String(value), "HH:mm:ss").format("HH:mm");
  }
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
